import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'

import { HtmlContent } from './html-content'

export function RaffleBanner() {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const isRafflePage = location.pathname.includes('/raffle')
  const isGameplay = location.pathname.includes('/play')
  const isSportsbookPage = location.pathname.includes('/sports')

  if (isGameplay || isSportsbookPage || isRafflePage) {
    return null
  }

  return (
    <ReactRouter.Link
      style={{
        textDecoration: 'none!important',
      }}
      to={`/raffle`}
    >
      <ThemeUI.Box
        sx={{
          width: '100%',
          background: '#E51953',
          height: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'Inter, sans-serif',
          fontSize: '16px',
          fontWeight: '600',
          color: 'white',
        }}
      >
        <HtmlContent
          html={{
            __html: translate('raffle.topbar.description'),
          }}
        />
      </ThemeUI.Box>
    </ReactRouter.Link>
  )
}

Herz.I18n.Loader.preload(['raffle.topbar.description'], RaffleBanner)
