import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'

import * as Utils from './utils'
import { useApiUrl } from './app-config'

const INITIAL_STATE = {
  apiUrl: process.env.API_URL,
  isRaffleEnabled: process.env.RAFFLE_ENABLED,
  isRaffleLinkEnabled: process.env.RAFFLE_LINK_ENABLED,
}

export const COUNTRY_CODE_UPDATED = 'boom/config/COUNTRY_CODE_UPDATED'
export const API_URL_UPDATED = 'boom/config/API_URL_UPDATED'
export const INIT = 'boom/config/INIT'
export const WEBP_SUPPORT_UPDATED = 'boom/config/WEBP_SUPPORT_UPDATED'

export function init(configuration) {
  return {
    type: INIT,
    error: configuration instanceof Error,
    payload: configuration,
  }
}

export function updateApiUrl(apiUrl) {
  return {
    type: API_URL_UPDATED,
    payload: apiUrl,
  }
}

export function updateCountryCode(countryCode) {
  return {
    type: COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updateWebpSupport(boolean) {
  return {
    type: WEBP_SUPPORT_UPDATED,
    payload: boolean,
  }
}

export function fetch() {
  return Api.fetchConfig({
    success: (res) => init({ ...res.value }),
    failure: () => init(new Error('errors.http.fetch-failure')),
    version: 1,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case COUNTRY_CODE_UPDATED: {
      return {
        ...state,
        countryCode: action.payload,
      }
    }

    case API_URL_UPDATED: {
      return {
        ...state,
        apiUrl: action.payload,
      }
    }

    case WEBP_SUPPORT_UPDATED: {
      return {
        ...state,
        hasWebpSupport: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export function getApiUrl(state) {
  return state?.apiUrl
}

export function getBrand(state) {
  return state?.brand
}

export function getCountryCode(state) {
  return state?.countryCode
}

export function getEnvironment(state) {
  return state?.environment
}

export function getGtmContainerId(state) {
  return state?.gtmContainerId
}

export function isIpInternal(state) {
  return state?.isIpInternal || false
}

export function getUserId(state) {
  return state?.username
}

export function getSocialMediaFooterSection(state) {
  return state?.footerSections?.socialMedia
}

export function getLiveChatDepartment(state, language) {
  return (
    (language && state?.liveChatDepartments?.[language]) ||
    state?.liveChatDepartments?.['en'] ||
    {}
  )
}

export function getShuftiProEnabled(state) {
  return state?.shufti?.enabled
}

export function getHasWebpSupport(state) {
  return state?.hasWebpSupport
}

// Component
export function KeepReduxConfigurationUpdated() {
  const dispatch = ReactRedux.useDispatch()
  const { country } = Utils.Configuration.useConfiguration()
  const apiUrl = useApiUrl()

  // Keep redux state up to date with GQL state
  React.useEffect(() => {
    dispatch(updateCountryCode(country.alpha2))
  }, [country.alpha2, dispatch])

  React.useEffect(() => {
    dispatch(updateApiUrl(apiUrl))
  }, [apiUrl, dispatch])

  return null
}

export function isRaffleEnabled(state) {
  return Boolean(state?.isRaffleEnabled === '1')
}

export function isRaffleLinkEnabled(state) {
  return Boolean(state?.isRaffleLinkEnabled === '1')
}
