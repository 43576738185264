import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import { HelmetProvider } from 'react-helmet-async'

import * as Herz from '@rushplay/herz'

import * as AppConfig from './app-config'
import * as Cookies from './cookies'
import * as DegbugTranslations from './dev-tools/debug-translations'
import * as Http from './http'
import * as Logger from './logger'
import * as Suspense from './suspense'
import * as Theming from './theming'
import * as Translations from './translations'
import { GraphqlProvider } from './graphql-provider'
import { RaffleCampaignsProvider } from './raffle-page/use-fetch-raffle-campaigns'
import { Router } from './router'
import { SeonProvider } from './seon-provider'

/**
 * Top-level provider composing providers necessary for application runtime
 * @param {Object} props
 */
export function GlobalProvider(props) {
  return (
    <Herz.Logging.LoggerProvider instance={Logger.react}>
      <Suspense.Provider>
        <HelmetProvider context={props.context}>
          <ReactRedux.Provider store={props.store}>
            <Http.Provider request={props.request} response={props.response}>
              <Cookies.Provider cookies={props.cookies}>
                <AppConfig.Provider>
                  <SeonProvider>
                    <Router context={props.context}>
                      <GraphqlProvider ssrCache={props.ssrCache}>
                        <Suspense.Boundary>
                          <Theming.Provider>
                            <Herz.I18n.LoaderProvider loader={props.i18nLoader}>
                              <Suspense.Boundary>
                                <DegbugTranslations.Provider>
                                  <Translations.Provider>
                                    <RaffleCampaignsProvider>
                                      {props.children}
                                    </RaffleCampaignsProvider>
                                  </Translations.Provider>
                                </DegbugTranslations.Provider>
                              </Suspense.Boundary>
                            </Herz.I18n.LoaderProvider>
                          </Theming.Provider>
                        </Suspense.Boundary>
                      </GraphqlProvider>
                    </Router>
                  </SeonProvider>
                </AppConfig.Provider>
              </Cookies.Provider>
            </Http.Provider>
          </ReactRedux.Provider>
        </HelmetProvider>
      </Suspense.Provider>
    </Herz.Logging.LoggerProvider>
  )
}

GlobalProvider.propTypes = {
  basename: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.object,
  cookies: PropTypes.object,
  i18nLoader: PropTypes.object,
  location: PropTypes.string,
  request: PropTypes.object,
  response: PropTypes.object,
  ssrCache: PropTypes.func,
  store: PropTypes.object.isRequired,
}
